<template>
  <div class="manage-users-edit">
    <div class="container fluid">
      <div class="card p-3">
        <h2 class="mb-4">Add User</h2>

        <user-form
          :roleOptions="roleOptions"
          :isRoleLoading="isRoleLoading"
          @submit="createUser"
        ></user-form>
      </div>
    </div>
  </div>
</template>

<script>
import { role as roleAPI, user as userAPI } from "@/api";
import UserForm from "@/components/User/UserForm";
import { UserModel } from "@/models";
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

const OWNER_ROLE_ID = 1;

export default {
  setup(props, context) {
    let toDeveloperUserList = () => {
      context.root.$router.push({
        name: "ManageUsers"
      });
    };
    const submitHandlerOptions = {
      role: "developer user",
      successCallback: toDeveloperUserList
    };
    const { handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleSubmitError
    };
  },
  components: {
    UserForm
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isRoleLoading: false,
      roleOptions: []
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getRoles();
    },
    async getRoles() {
      try {
        this.isRoleLoading = true;
        let res = await roleAPI.getRoles({});
        this.roleOptions = this._.cloneDeep(
          res.data.filter((role) => role.id != OWNER_ROLE_ID)
        );

        this.isRoleLoading = false;
      } catch (error) {
        console.log(error);
        this.isRoleLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch role options. Please try again later."
        });
      }
    },
    async createUser(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await userAPI.createUser(UserModel.postPayload(payload));
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "User has been created successfully."
        });
        this.$router.push({ name: "ManageUsers" });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    }
  }
};
</script>

<style lang="scss"></style>
